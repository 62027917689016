import { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { formTexts } from 'i18n';
import { DeleteOutlineIcon } from 'components/Icons';
import { validateWeight } from 'helpers';
import {
  useSubGoalTitle,
  useErrorClearing,
  useGoalAssessmentValues,
} from 'hooks/GoalsAndSubgoalsForm';
import { useFormContext, useWatch } from 'react-hook-form';
import OneLineTextWithTooltip from 'components/OneLineTextWithTooltip';
import cx from 'classnames';
import {
  AssessmentScaleGroupAlias,
  assessmentScaleGroupIdMap,
} from 'store/interfaces/ScorecardExternalSubgoalRO';
import { GoalField, SubGoalProps } from './interfaces';
import {
  DeleteButton,
  DeleteButtonWrapper,
  FieldHolderWrapper,
  SubGoalContainer,
  TitleName,
  TitleWeight,
  TitleWithAction,
  TitleWrapper,
} from './GoalsAndSubgoalsForm.styled';
import FieldHolder from './FieldHolder.component';
import NameField from './NameField.component';

export default function SubGoal({
  index,
  remove,
  goalIndex,
  canDelete,
  canEdit,
  isCuSat,
  canEditWeight,
  active,
  isCommonGoalEdit,
}: SubGoalProps) {
  useErrorClearing(index, goalIndex);
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<GoalField>();
  const subGoalName = `goals.${goalIndex}.subgoals.${index}`;
  const {
    assessmentScaleGroupIdName,
    assessmentScaleGroups,
    allAssessmentScaleGroups,
  } = useGoalAssessmentValues(subGoalName);
  const onDelete = () => remove(index);
  const { title, weight } = useSubGoalTitle(goalIndex, index);

  const [isCustom] = useWatch({
    name: [`goals.${goalIndex}.isCustom`],
  });
  const [assessmentScaleID] = useWatch({
    name: [assessmentScaleGroupIdName],
  });
  const [subgoalNameField] = useWatch({
    name: [`${subGoalName}.name`],
  });

  const isTextAssessmentScale =
    assessmentScaleID ===
    assessmentScaleGroupIdMap[AssessmentScaleGroupAlias.TEXT];

  useEffect(() => {
    if (isTextAssessmentScale) {
      setValue(`goals.${goalIndex}.subgoals.${index}.weight`, 0, {
        shouldValidate: true,
      });
    }
  }, [index, goalIndex, isTextAssessmentScale, setValue]);

  const selectedAssessmentScaleGroup = allAssessmentScaleGroups.find(
    (group) => group.id === assessmentScaleID,
  );

  return (
    <SubGoalContainer
      id={subGoalName.replaceAll('.', '')}
      className={cx({ active })}
    >
      <TitleWithAction mb="20px">
        <TitleWrapper>
          <TitleName>
            <OneLineTextWithTooltip variant="heading4" text={title} />
          </TitleName>
          {!isCuSat && (
            <TitleWeight>
              <Typography variant="heading4">{weight}%</Typography>
            </TitleWeight>
          )}
        </TitleWrapper>
        <Stack alignItems="center" justifyContent="flex-end">
          {canDelete && canEdit && !isCuSat && (
            <DeleteButtonWrapper>
              <DeleteButton onClick={onDelete}>
                <DeleteOutlineIcon />
              </DeleteButton>
            </DeleteButtonWrapper>
          )}
        </Stack>
      </TitleWithAction>

      <NameField
        name={`${subGoalName}.name`}
        parentFieldName={`goals.${goalIndex}.subgoals`}
        isCuSat={isCuSat}
        canEdit={canEdit}
        isCustom={isCustom}
        index={index}
        placeholder={formatMessage({
          id: 'pages.reviewCycles.nameSubGoalPlaceholder',
          defaultMessage: 'Ex., Overall Satisfaction',
        })}
        selectOnFocus={
          subgoalNameField === formTexts.untitledSubgoal.defaultMessage
        }
        dataTestId="subgoal-name-field"
      />

      {!isCuSat && (
        <Stack direction="row" gap={1}>
          <FieldHolderWrapper maxWidth="160px">
            <FieldHolder
              name={`${subGoalName}.weight`}
              rules={{
                required: isTextAssessmentScale
                  ? undefined
                  : formatMessage(formTexts.errorRequired),
                validate:
                  isTextAssessmentScale || isCommonGoalEdit
                    ? undefined
                    : (value: string) => validateWeight(value, formatMessage),
              }}
              type="text"
              label={formatMessage(formTexts.weight)}
              selectOnFocus
              isTextAssessmentScale={isTextAssessmentScale}
              disabled={!canEditWeight || isTextAssessmentScale}
              isCustomGoal={isCustom}
              fieldProps={{
                size: 'medium',
              }}
              dataTestId="subgoal-weight-field"
              required={!isTextAssessmentScale}
            />
          </FieldHolderWrapper>
          <FieldHolderWrapper className="assessmentScale">
            <FieldHolder
              name={assessmentScaleGroupIdName}
              type="select"
              label={formatMessage(formTexts.assessmentScale)}
              options={assessmentScaleGroups}
              fieldProps={{
                size: 'medium',
              }}
              disabled={!canEdit}
              placeholder={selectedAssessmentScaleGroup?.name}
              withPlaceholderWhenOptionNotFound
            />
          </FieldHolderWrapper>
        </Stack>
      )}
      <FieldHolderWrapper>
        <FieldHolder
          name={`${subGoalName}.description`}
          maxLength={3000}
          type="textarea"
          disabled={isCuSat || !canEdit}
          label={formatMessage(formTexts.description)}
          placeholder={formatMessage({
            id: 'pages.reviewCycles.descriptionSubGoalPlaceholder',
            defaultMessage:
              'Ex., Please rate your satisfaction on the cooperation with the person',
          })}
          isCustomGoal={isCustom}
          hasLinks
          fieldProps={{
            size: 'medium',
          }}
        />
      </FieldHolderWrapper>
    </SubGoalContainer>
  );
}
